.container_items {
    justify-content: center;
    padding: 0 70px;
    font-size: 21px;
    @media screen and (max-width: 991.98px) {
        padding: 0;
        font-size: 18px;
    }
    .container_items_block_title {
        h2 {
            padding: 10px 0 25px;
        }
    }
    .container_item_carousel {
        .preview {
            .container_items_block {
                display: flex;
                justify-content: center;
                .container_items_block_img {
                    .znak {
                        position: relative;
                        cursor: default;
                        &:before {
                            content: "";
                            background-image: url(../img/eurocontainer-znak-big.png);
                            background-size: 180px 20px;
                            background-position: top;
                            width: 100%;
                            height: 20%;
                            background-repeat: no-repeat;
                            margin-top: 51%;
                            position: absolute;
                        }
                        img {
                            max-width: 345px;
                        }
                    }
                }
                .container_items_block_info {
                    align-self: center;
                    margin: 0 30px;
                    min-width: 182px;
                    max-width: 230px;
                    width: 100%;
                    .color {
                        justify-content: center;
                        display: flex;
                        img {
                            border-radius: 50%;
                            width: 21px;
                            height: 21px;
                            margin: 0 2px;
                        }
                        a {
                            cursor: pointer;
                        }
                    }
                    .price {
                        border: 2px solid #259eb3;
                        margin-top: 20px;
                        div {
                            font-size: 26px;
                            color: #fff;
                            background: #259eb3;
                        }
                        span {
                            font-size: 26px;
                        }
                    }
                    .download {
                        a {
                            flex-direction: row-reverse;
                            display: flex;
                            font-family: RobotoCondensedLight;
                            font-size: 21px;
                        }
                    }
                    .buttonPopup {
                        margin-top: 25px;
                        a {
                            background: #f7c507!important;
                            text-transform: uppercase!important;
                            font-size: 21px!important;
                            padding: 10px 35px!important;
                            color: #fff;
                            text-decoration: none;
                            &:hover {
                                background: linear-gradient(180deg,#fae490 0,#f7c507)!important;
                            }
                        }
                    }
                }
                .container_items_block_characteristic {
                    display: flex;
                    flex-direction: column-reverse;
                    height: 100%;
                    p {
                        font-size: 21px;
                        text-transform: uppercase;
                        margin-bottom: 30px;
                    }
                    ul {
                        padding-left: 20px;
                        li {
                            text-align: left;
                            font-size: 20px;
                            span {
                                font-family: RobotoCondensedLight;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
            .blockImage {
                margin-bottom: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                a::before {
                    content: "";
                    background-image: url(../img/eurocontainer-znak-big.png);
                    background-size: 145px 12px;
                    background-position: top;
                    width: 28.5%;
                    height: 7%;
                    background-repeat: no-repeat;
                    position: absolute;
                    margin-top: 125px;
                    @media screen and (max-width: 767.98px) {
                        left: 0;
                        width: 100%;
                    }
                    @media screen and (min-width:768px) and (max-width: 991.98px) {
                        width: 36%;
                    }
                }
                img {
                    max-width: 250px;
                    max-height: 250px;
                    margin: 5px 15px;
                    @media screen and (max-width: 991.98px) {
                        margin: 8px 0px;
                    }
                    cursor: pointer;
                }
            }
        }
    }
    .container_item_description {
        padding: 40px 45px 0;
        p {
            font-size: 16px;
            text-align: left;
            font-family: RobotoCondensedRegular;
        }
        .end {
            margin: 50px 0 30px;
        }
        ul {
            li {
                p {
                    font-size: 16px;
                    font-family: RobotoCondensedRegular;
                }
            }
        }
        h3 {
            margin-top: 2em;
            background: #ffde00;
            font-family: RobotoCondensedBold;
            font-size: 1.2em;
            padding: .5em .9em;
        }
        .container_item__table {
            display: flex;
            .col-lg-2, .col-lg-1 {
                padding: 0;
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 55px;
                    &:nth-child(1) {
                        border-bottom: 1px solid #ccc;
                        margin-bottom: 10px;
                    }
                    p {
                        &:nth-child(1) {
                            margin: 0;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 991.98px) {
    .modal {
        padding: 0 !important;
        .container_items {
            .container_item_carousel {
                .preview {
                    .container_items_block {
                        flex-direction: column;
                        margin: 0;
                        .container_items_block_characteristic {
                            p {
                                margin: 20px 0 0;
                            }
                        }
                        .znak {
                            img {
                                max-width: 300px;
                                margin-left: -29px;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            .description {
                padding: 0 0 20px;
                font-family: RobotoCondensedRegular;
                ul {
                    margin: 0;
                }
                tbody {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    tr {
                        display: flex;
                        width: 100%;
                    }
                }
                .container_item__table {
                    display: flex;
                }
            }
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 991.98px) {
    .one {
        tr {
            display: flex !important;
            td {
                display: flex;
                width: 50% !important;
                align-items: center;
                justify-content: center;
                min-height: 100px !important;
                border: 0;
                p {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    .forma {
        width: 90% !important;
    }
}

.mfp-figure {
    figure::before {
        content: "";
        background-image: url(../img/eurocontainer-znak-big.png);
        background-size: 390px 50px;
        background-position: top;
        width: 100%;
        height: 11%;
        background-repeat: no-repeat;
        position: absolute;
        top: calc(50% - 36px);
        @media screen and (max-width:767.98px) {
            content: "";
            background-image: url(../img/eurocontainer-znak-big.png);
            background-size: 273px 50px;
            background-position: top;
            width: 100%;
            height: 24%;
            background-repeat: no-repeat;
            position: absolute;
            top: calc(50% - 36px);
        }
    }
}

.briefDescription {
    text-align: left;
    p {
        font-family: RobotoCondensedRegular;
    }
    .bold {
        font-family: RobotoCondensedBold;
        text-align: center;
    }
    ul {
        list-style: none;
        padding-left: 70px;
        @media screen and (max-width: 991.98px) {
            padding-left: 45px;
        }
        li {
            position: relative;
            padding-left: 10px;
            &::before {
                content: '✔';
                position: absolute; 
                top: 0; 
                left: -25px;
            }
        }
    }
}

.one {
    border-color: #259eb3;
    border-left: 0;
    border-right: 0;
    border-top: 2px solid #259eb3;
    border-bottom: 2px solid #259eb3;
    text-align: left;
    @media screen and (max-width: 991.98px) {
        text-align: center;
    }
    tr {
        border-bottom: 2px solid #259eb3;
        td {
            border: none;
            &:nth-of-type(odd) {
                background: #a6e6f18f;
            }
        }
    }
    p {
        margin: 0 0 0 35px;
        @media screen and (max-width: 991.98px) {
            margin: 0 !important;
        }
    }
}

.two {
    border: none;
    td {
        border: none;
    }
    ul {
        list-style: none;
        padding-left: 65px;
        @media screen and (max-width: 767.98px) {
            padding-left: 20px;
        }
        li {
            position: relative;
            padding-left: 10px;
            &::before {
                content: '✔';
                position: absolute; 
                top: 0; 
                left: -25px;
            }
        }
    }
}

.three {
    border: 5px solid #259eb3;
    width: 100%;
    p:nth-of-type(1) {
        color: #259eb3;
        text-align: center;
        margin-bottom: 0;
        margin-top: 10px;
    }
    p:nth-of-type(2) {
        margin: 0 0 0 70px;
        @media screen and (max-width:767.98px) {
            margin: 0 auto !important;
            text-align: center;
        }
    }
    ul {
        padding-left: 60px;
        @media screen and (max-width:519.98px) {
            margin: 0 0 0 20px !important;
            padding: 0;
        }
        @media screen and (min-width: 520px) and (max-width:767.98px) {
            margin: 0 auto !important;
            padding: 0;
        }
        li {
            padding-left: 10px;
            @media screen and (max-width:767.98px) {
                padding-left: 0 !important;
            }
        }
    }
    td {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
}

.modalFooter {
    margin-bottom: 20px;
    margin-top: 40px;
    .modalFooterTitle {
        h2 {
            color: #259eb3;
            border-top: 2px solid #259eb3;
            border-bottom: 2px solid #259eb3;
            @media screen and (max-width: 767.98px) {
                font-size: 20px;
            }
        }
        .modalFooterItems {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 767.98px) {
                display: block;
            }
            .modalFooterLeft {
                text-align: left;
                p {
                    font-family: RobotoCondensedRegular;
                }
        
            }
            .modalFooterRight {
                text-align: right;
                @media screen and (max-width: 767.98px) {
                    text-align: left;
                }
                p {
                    font-family: RobotoCondensedRegular;
                    &:nth-of-type(1) {
                        font-family: RobotoCondensedBold;
                    }
                    a {
                        font-family: RobotoCondensedBold;
                        color: #000;
                        @media screen and (max-width: 767.98px) {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

.sertificate {
    img {
        max-width: 250px;
        max-height: 250px;
        margin: 5px 15px;
        cursor: pointer;
    }
}

.blockImageTitle {
    font-family: RobotoCondensedRegular;
    background: #259eb3;
    text-align: center;
    font-size: 25px;
    padding: 5px 0;
    color: #fff;
    text-transform: uppercase;
    margin-top: 40px;
}