//form-open
.form {
    color: #259eb3;
    .formTitle {
        margin: 0 auto;
        padding: 60px 0 90px;
        h2 {
            font-size: 40px;
            text-align: center;
            font-family: RobotoCondensedRegular;
            span {
                font-size: 28px;
            }
        }
    }
    .formBlocks {
        display: flex;
        .formBlocksItem {
            p {
                font-size: 21px;
                color: #000;
                width: 65%;
                font-family: RobotoCondensedRegular;
                margin: 0 auto 50px;
                span {
                    color: #259eb3;
                    font-family: RobotoCondensedBold;
                }
            }
            .formPhone {
                display: flex;
                flex-direction: column;
                a {
                    color: #259eb3;
                    text-decoration: none;
                    font-size: 40px;
                    text-align: center;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            form {
                display: flex;
                flex-direction: column;
                input, textarea {
                    font-family: RobotoCondensedRegular;
                    width: 300px;
                    padding-left: 15px;
                }
                input {
                    height: 40px;
                    margin-bottom: 20px;
                }
                .btn {
                    background: #259eb3;
                    color: #fff;
                    text-transform: uppercase;
                    margin: 25px auto 0;
                    padding: 5px 20px;
                    border-radius: 0;
                    // transition: all .5s ease;
                    &:hover {
                        background: #fff;
                        color: #259eb3;
                        border-bottom: 1px solid #259eb3;
                    }
                }
            }
        }
    }
}

// honeypot
[name="username"] {display: none}

@media screen and (min-width:320px) and (max-width: 991.98px) {
    .form {
        .formTitle {
            padding: 20px 0;
            h2 {
                font-size: 24px;
                font-family: RobotoCondensedBold;
                span {
                    font-size: 18px;
                    font-family: RobotoCondensedRegular;
                }
            }
        }
        .formBlocks {
            flex-direction: column-reverse;
            margin: 0 auto;
            .formBlocksItem {
                margin: 0 auto;
                p {
                    font-size: 18px;
                    width: 90%;
                    margin: 20px 15px;
                }
                .formPhone {
                    margin-bottom: 35px;
                    a {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:576px) and (max-width: 991.98px) {
    .form {
        .formBlocks {
            .formBlocksItem {
                p {
                    width: 100%;
                }
            }
        }
    }
}

//form-close