// header-open
body {
    font-family: RobotoCondensedBold;
}
button {
    font-family: RobotoCondensedRegular;
}
.hide {
    display: none !important;
}
.header {
    background: url(../img/bg-landscape.jpg) no-repeat center top;
    padding-top: 15px;
    .navigation {
        width: 100%;
        display: flex;
        align-items: center;
        ul {
            list-style: none;
            color: #fff;
            text-transform: uppercase;
            display: flex;
            justify-content: space-evenly;
            margin: 0;
            li {
                display: flex;
                align-items: center;
                font-family: RobotoCondensedBold;
                &:nth-child(1) {
                    font-family: RobotoCondensedRegular;
                }
                a {
                    color: #fff;
                    // transition: all .5s ease;
                    &:hover {
                        color: #f7c507;
                        text-decoration: underline;
                    }
                    span {
                        text-transform: lowercase;
                    }
                }
            }
        }
        .navigationPhone {
            align-items: center;
            display: flex;
            justify-content: space-evenly;
            .language {
                display: flex;
                flex-direction: column;
                display: none;
                .btn {
                    font-size: 12px;
                    padding: 0;
                    width: 30px;
                    color: #fff;
                    border: 1px solid #fff;
                    &:hover {
                        opacity: 0.7;
                    }
                }
                .active {
                    background: #fff;
                    color: #000;
                }
            }
        }
    }
    .evroContainer {
        margin: 0 auto;
        padding: 60px 0;
        .evroContainerLogo {
            width: 80%;
            display: flex;
            margin: 0 auto;
            p {
                font-size: 56px;
                text-transform: uppercase;
                margin-left: 30px;
            }
        }
    }
    .evroContainerCard {
        margin: 0 auto;
        .card-group {
            display: block;
            .cardImages {
                display: flex;
                align-items: flex-end;
                .card {
                    background: transparent;
                    border: 0;
                }
            }
            .cardButton {
                display: flex;
                justify-content: space-evenly;
                margin-top: 30px;
                a {
                    background: #259eb3;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    width: 186px;
                    font-size: 40px;
                    border-radius: 10px;
                    padding: 5px 0;
                    margin-top: -20px;
                    text-decoration: none;
                    // transition: all .5s ease;
                    &:hover {
                        color: #f7c507;
                    }
                    &:hover span {
                        color: #fff;
                    }
                    span {
                        font-size: 18px;
                        color: #f7c507;
                        font-family: RobotoCondensedRegular;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    .description {
        .descriptionOffer {
            display: flex;
            margin: 70px 0 35px;
            .descriptionOfferImg {
                display: flex;
                justify-content: flex-end;
                margin-right: -30px;
                z-index: 99;
                img {
                    width: 55%;
                }
            }
            .descriptionOfferText {
                background: #f7c507;
                border: 1px solid #000;
                border-radius: 10px;
                border-left: 0;
                padding: 0px 95px;
                text-align: center;
                width: 89%;
                h1 {
                    font-size: 24px;
                    text-transform: uppercase;
                    margin-top: 10px;
                    font-family: RobotoCondensedRegular;
                }
                p {
                    font-size: 24px;
                    text-transform: uppercase;
                    margin: 0;
                }
            }
        }
        .descriptionSpecification {
            p {
                font-size: 21px;
                text-align: center;
                padding: 0 155px;
                font-family: RobotoCondensedRegular;
                span {
                    color: #259eb3;
                    text-transform: uppercase; 
                }
            }   
            .navigationPhoneModal {
                text-align: center;
                margin-bottom: 50px;
                button {
                    background: #f7c507!important;
                    text-transform: uppercase!important;
                    font-size: 21px!important;
                    padding: 10px 35px!important;
                    // transition: none;
                    &:hover {
                        background: linear-gradient(180deg,#fae490 0,#f7c507) !important;
                    }
                }
            }
        }
    }
}

.contButton {
    background: #f7c507 !important;
    border: 1px solid #fff !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-size: 14px !important;
    border-radius: 0 !important; 
}

.navigationPhoneBtn {
    display: flex;
    .btn-group {
        a {
            color: #fff;
            // transition: all .5s ease;
            &:hover {
                color: #f7c507;
                text-decoration: underline;
            }
        }
        .btn {
            color: #fff;
            padding: 0;
            margin-left: 10px;
            background: transparent;
            border: 0;
            &:hover {
                color: #f7c507;
            }
        }
    }
    .dropdown-menu.show {
        display: block;
        display: flex;
        flex-direction: column;
        text-align: center;
        background: transparent;
        border: 0;
        transform: translate3d(-20px, 20px, 0px) !important;
        left: 3px !important;
        a {
            margin-bottom: 5px;
        }
    }
    .navigationPhoneBtnIcon {
        .btn {
            vertical-align: top;
            border: 0;
            padding: 0;
            margin-left: 10px;
        }
    }
}

.forma {
    display: flex;
    flex-direction: column;
    margin: 20px auto 0;
    width: 50%;
    input {
        margin-bottom: 15px;
    }
    .btn {
        background: #f7c507;
        margin: 20px 0;
        padding: 5px !important;
        &:hover {
            background: #fff;
        }
    }
}

.lb_call-btn {
    display: none;
}

.mobileNav {
    display: none;
}

@media screen and (min-width: 320px) and (max-width: 991.98px) {
    .header {
        background-size: 245vw;
        padding: 0;
        img {
            width: 4em;
        }
        h1 {
            font-size: 17px !important;
        }
        p {
            font-size: 17.5px !important;
            margin-left: 15px !important;
            margin-bottom: 0 !important;
        }
        .navigation {
            .col-lg-5 {
                display: none;
            }
        }
        .btn-group {
            margin-left: 15px;
            .btn {
                display: none;
            }
        }
        .evroContainer {
            padding: 25px 0;
        }
        .evroContainerLogo {
            margin: 0 15px !important;
        }
        .card-group {
            flex-direction: row;
            .cardImages {
                .col-xs-8 {
                    padding-left: 0;
                }
                .mobileImg {
                    width: 130% !important;
                }
                .card-img-top {
                        width: 60%;
                        margin: 0 auto;
                    }
            }
            .cardButton {
                margin-top: 0 !important;
                margin-bottom: 20px;
                a {
                    font-size: 20px !important;
                    width: 75px!important;
                    margin-top: 10px !important;
                    span {
                        display: none;
                    }
                }
            }
        }
        .navbar-toggler {
            background: #f7c507;
            width: 50px;
            height: 43px;
            padding: 0;
            border-radius: 0;
            .navbar-toggler-icon {
                padding: 2px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .navbar {
            justify-content: flex-end;
            padding: 0;
        }
        .navigation {
            position: absolute;
            left: 0;
        }
        .descriptionOffer {
            margin-top: 0 !important;
            .descriptionOfferImg {
                display: none !important;
            }
            .descriptionOfferText {
                border-left: 1px solid #000 !important;
                padding: 0 15px !important;
                width: 100% !important;
                margin: 0 15px;
                justify-content: center !important;
                p {
                    font-size: 14px !important;
                    margin: 15px 0 20px !important;
                }
            }
        }
        .descriptionSpecification {
            p {
                display: none;
            }
        }
    }
    .hide {
        display: block !important;
    }
    .desktopNav {
        display: none;
    }

    .mobileNav {
        display: flex;
        padding: 0;
        justify-content: flex-end;
        .btn {
            background: #f7c507;
            width: 60px;
            height: 50px;
            padding: 0;
            border-radius: 0;
            margin: 15px;
            img {
                width: 35px;
            }
        }
        .modal {
            .modal-dialog {
                margin: 0;
                max-width: 100%;
                .modal-content {
                    padding: 0 40px 10px;
                    border: none;
                    border-radius: 0;
                    .modal-header {
                        border-bottom: 0;
                        .close {
                            padding: 25px 0 10px;
                            margin-right: -30px;
                            img {
                                width: 35px;
                            }
                        }
                    }
                    .callMe {
                        text-align: center;
                        background: #f7c507!important;
                        text-transform: uppercase!important;
                        font-size: 21px!important;
                        padding: 10px 35px!important;
                        color: #fff;
                        margin: 20px auto 10px;
                        a {
                            opacity: 0;
                            position: absolute;
                            top: 90px;
                            left: 0;
                            right: 0px;
                            padding-bottom: 20px;
                        }
                    }
                    .language {
                        font-size: 21px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 10px;
                        p {
                            margin: 10px 0 5px;
                        }
                        a {
                            color: #000;
                            font-size: 24px;
                        }
                        .active {
                            color: #259eb3;
                        }
                    }
                    .navbar-nav {
                        color: #259eb3;
                        margin-top: 10px;
                        .nav-item {
                            font-size: 24px;
                            justify-content: center;
                            border-bottom: 1px solid #000;
                            &:nth-child(1) {
                                border-bottom: 0;
                            }
                            &:nth-child(2) {
                                border-bottom: 0;
                            }
                            &:nth-child(3) {
                                border-bottom: 0;
                            }
                            .nav-link {
                                color: #259eb3;
                            }
                        }
                    }
                    .contact {
                        font-size: 21px;
                        text-align: center;
                        border-bottom: 1px solid #000;
                        p {
                            margin: 10px 0 5px;
                        }
                        .icon {
                            img {
                                width: 2.5em;
                            }
                        }
                        .numberPhone {
                            display: flex;
                            flex-direction: column;
                            a {
                                font-size: 28px;
                                margin-bottom: 5px;
                                color: #000;
                                font-family: RobotoCondensedRegular;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 991.98px) {
    .header {
        .evroContainer {
            margin: 0;
        }
        .evroContainerCard {
            margin-bottom: 15px;
        }
    }
}
// header-close