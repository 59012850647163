// customer-open
.customer {
    background: linear-gradient(#d5eef3,#fff 8%);
    .customerTitle {
        margin: 80px auto 60px;
        h2 {
            color: #259eb3;
            font-size: 40px;
        }
    }
    .customerItem {
        .card-group {
            justify-content: center;
            .card {
                display: block;
                flex-basis: 15%;
                border: 0;
                text-align: center;
                background: transparent;
                .card-title {
                    font-family: RobotoCondensedRegular;
                    font-size: 14px;
                }
                .card-img-top {
                    width: 170px;
                }
            }
        }
    }
}

@media screen and (min-width: 320px) and (max-width:991.98px) {
    .customer {
        display: none;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .customer .customerItem .card-group .card .card-img-top {
        width: 11vw;
    }
}
// customer-close