//services-open
.services {
    background: #259eb3;
    color: #fff;
    .servicesItem{
        .servicesItemTitle {
            h2 {
                font-size: 40px;
                text-align: center;
                padding: 60px 0 70px;
            }
        }
        .card-group {
            .card {
                background: transparent;
                border: 0;
                flex-basis: 33.3%;
                text-align: center;
                padding-bottom: 50px;
                .card-img-top {
                    max-width: 218px;
                    margin: 0 auto;
                }
                .card-title {
                    border-bottom: 1px solid #fff;
                    line-height: 2;
                    font-family: RobotoCondensedRegular;
                    text-transform: uppercase;
                }
                .card-text {
                    font-family: RobotoCondensedRegular;
                    font-size: 21px;
                }
            }
        }
    }
}

.delivery {
    background: #4dbed2;
    h2 {
        font-family: RobotoCondensedRegular;
        font-size: 21px;
        text-align: center;
        color: #fff;
        padding: 30px 0;
        margin: 0;
    }
}

.serviceDelivery {
    background: #81dded;
    padding: 20px 0;
    .container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}

@media screen and (min-width: 320px) and (max-width: 991.98px) {
    .services {
        .servicesItem {
            .servicesItemTitle {
                h2 {
                    font-size: 24px;
                    padding: 20px 60px;
                }
            }
            .card-group {
                .card {
                    flex-basis: 50%;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
    .delivery {
        h2 {
            text-transform: uppercase;
        }
    }
    .serviceDelivery {
        .container {
            justify-content: center;
            flex-wrap: wrap;
            div {
                width: 50%;
                margin: 15px 0;
                text-align: center;
                img {
                    width: 85%;
                }
                .mobile {
                    width: 60%;
                }
            }
        }
    }
}

@media screen and (min-width:768px) and (max-width:991.98px) {
    .serviceDelivery {
        .container {
            div {
                img {
                    width: 60%;
                }
                .mobile {
                    width: 35%;
                }
            }
        }
    }
}

//services-close