// carousel-open
.carousel {
    background: url(../img/mapa-ua-917x625.svg) no-repeat center top;
    background-size: 65rem;
    padding-bottom: 215px;
    margin-top: 40px;
    .slick-carousel {
        width: 100%;
        .slick-prev {
            transform: rotate(180deg);
        }
        .slick-slide {
            text-align: center;
        }
        .slick-carousel-item {
            display: flex;
            flex-direction: column;       
            img {
                width: 170px;
                margin: 0 auto;
            } 
        }
    }
    .carouselTitle {
        margin: 0 auto;
        text-align: center;
        padding: 100px 0 80px;
        h2 {
            font-size: 40px;
            color: #259eb3;
        }
    }
}

@media screen and (min-width:320px) and (max-width: 991.98px) {
    .carousel {
        background: url(../img/mapa-ua-917x625.svg) no-repeat center;
        background-size: 18rem;
        padding: 0 0 20px;
        margin-top: 20px;
        .carouselTitle {
            padding: 0;
            h2 {
                font-size: 28px;
            }
        }
        .slick-carousel {
            .slick-prev {
                left: 35px;
                z-index: 999;
            }
            .slick-next {
                right: 35px;
                top: 45%;
                z-index: 999;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1220px) {
    .carousel {
        .slick-carousel {
            .slick-prev {
                left: -10px;
                z-index: 999;
            }
            .slick-next {
                right: -10px;
                top: 45%;
                z-index: 999;
            }
        }
    }
}

// carousel-close