//footer-open
.footer {
    margin-bottom: 40px;
    margin-top: 70px;
    background: linear-gradient(#d5eef3 0%, #ffffff 70%);
    .footerInfo {
        display: flex;
        width: 100%;
        .footerInfoContact {
            display: flex;
            flex-direction: column;
            padding-right: 100px;
            .email {
                font-size: 16px;
            }
            .image {
                text-align: center;
                img {
                    max-width: 120px;
                }
            }
            .icon {
                a {
                    &:hover {
                        border-bottom: 0;
                    }
                    img {
                        max-width: 45px;
                    }
                }
            }
            p {
                margin: 0;
            }
            a {
                color: #000;
                text-decoration: none;
                font-size: 21px;
                font-family: RobotoCondensedRegular;
                width: 75%;
                &:hover {
                    text-decoration: underline;
                }
            }
            .mobileViber {
                display: none;
            }
            span {
                font-family: RobotoCondensedRegular;
            }
        }
        .footerInfoMap {
            .mobileBlock {
                display: none;
                .email {
                    font-size: 16px;
                }
            }
            .navigationContein {
                margin-top: 20px;
                margin-bottom: 80px;
                ul {
                    list-style: none;
                    display: flex;
                    li {
                        color: #259eb3;
                        text-transform: uppercase;
                        & + li {
                            margin-left: 20px;
                        }
                        a {
                            color: #259eb3;
                            // transition: all .5s ease;
                            &:hover {
                                color: #000;
                            }
                            span {
                                text-transform: lowercase;
                            }
                        }
                    }
                }
            }
        }
    }
    .footerLanguage {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding: 20px 0;
        .active {
            color: #259eb3;
        }
        a {
            display: none;
            color: #000;
            & + a {
                margin-left: 20px;
            }
        }
    }
    hr {
        background: #000;
        margin: 60px 0 40px;
    }
    .zinaDesign {
        display: flex;
        justify-content: space-evenly;
        font-family: RobotoCondensedRegular;
        span {
            &:nth-of-type(1) {
                background: #ccc;
                padding: 3px;
            }
            &:nth-of-type(2) {
                border-bottom: 1px solid #ccc;
            }
        }
        a {
            color: #000;
        }
    }
    .scroll-top-wrap {
        @media screen and (max-width: 991.98px) {
            display: none;
        }
        text-align: center;
        .scroll-top-inner {
            z-index: 999;
            border-radius: 50%;
            width: 70px;
            height: 70px;
            position: fixed;
            cursor: pointer;
            left: 20px;
            bottom: 15px;
            background: #259eb3;
            opacity: 0.7;
            // transition: .5s;
            &:hover {
                opacity: 1;
            }
            span {
                line-height: 63px;
            }
        }
    }
}

@media screen and (min-width:320px) and (max-width: 991.98px) {
    .footer {
        margin: 0;
        hr {
            margin: 15px 0 0;
        }
        .zinaDesign {
            flex-direction: column;
            text-align: center;
            padding: 0;
            font-size: 12px;
            margin: 15px auto 0;
            div {
                margin-bottom: 15px;
                .mobileHide {
                        display: none;
                }
            }
        }
        .footerLanguage {
            justify-content: center;
        }
        .footerInfo {
            flex-direction: column-reverse;
            flex-wrap: wrap; 
            .footerInfoMap {
                width: 100%;
                .navigationContein {
                    margin-bottom: 40px;
                    ul {
                        flex-wrap: wrap;
                        padding: 0;
                        li {
                            font-size: 24px;
                            margin-bottom: 10px;
                            &:nth-child(1) {
                                width: 100%;
                                margin-left: 20px;
                            }
                            &:nth-child(3) {
                                margin-bottom: 25px;
                            }
                        }
                    }
                }
                .map {
                    div {
                        width: 100% !important;
                    }
                }
                iframe {
                    width: 100vw;
                }
            }
            .footerInfoContact {
                margin: 0 15px;
                padding: 0;
                .mobileBlockArrow {
                    display: flex;
                    a {
                        width: 80px;
                        background: #0f7a8c;
                        text-align: center;
                        height: 80px;
                        border-radius: 50%;
                        position: absolute;
                        right: 15px;
                        margin-top: -25px;
                        img {
                            margin: 25px auto;
                        }
                    }
                }
                a {
                    width: 235px;
                    font-size: 28px;
                }
                p {
                    font-size: 21px;
                }
                .icon {
                    text-align: center;
                    a {
                        margin: 0 10px;
                    }
                    img {
                        max-width: 40px;
                    }
                }
            }
            .mobileBlock {
                display: block !important;
                margin-bottom: 20px;
                .mobileBlockArrow {
                    padding: 0 20px;
                    .icon {
                        text-align: center;
                        margin-top: -10px;
                        a {
                            margin: 5px 5px;
                            img {
                                max-width: 45px;
                            }
                        }
                    }
                    a {
                        width: 235px;
                        font-size: 28px;
                        color: #000;
                        font-family: RobotoCondensedRegular;
                    }
                    p {
                        font-size: 21px;
                        span {
                            font-family: RobotoCondensedRegular;
                        }
                    }
                    .email {
                        font-size: 21px;
                    }
                }
            }
            .desktopBlock {
                display: none;
            }
        }
    }
    .mobileViber {
        display: inline !important;
    }
    .desktopViber {
        display: none !important;
    }
    .scroll-top-wrap {
        .scroll-top-inner {
            opacity: 1 !important;
        }
    }
}

.callPhone {
    z-index: 999;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    position: fixed;
    cursor: pointer;
    right: 20px;
    bottom: 15px;
    display: none;
    a {
        position: absolute;
        right: 0px;
        width: 70px;
        height: 70px;
        opacity: 0;
    }
}

.modal {
    .pop-up__wrp {
        .modal-header {
            justify-content: flex-end;
            padding: 0;
            button {
                font-size: 50px;
                padding: 0;
                margin: 0;
            }
            .pop-up_btn {
                color: #333;
                background-color: #fff;
                border-color: #ccc;
            }
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 991.98px) {
    .callPhone {
        display: block;
        left: 20px;
        bottom: 20px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1023.98px) {
    .footer {
        .footerInfoMap {
            margin-left: -3px !important;
        }
    }
    .desktopViber {
                display: inline !important;
            }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
    .footer {
        .footerInfo {
            .footerInfoContact {
                margin: 0 !important;
                padding: 0 !important;
            }
        }
    }
}

//footer-close