// containers-open
.containers {
    background: linear-gradient(#d5eef3 0%, #ffffff 8%);
    .containerHeader {
        text-align: center;
        margin: 0 auto;
        padding: 60px 0 30px;
        .containerTitle {
            h2 {
                font-size: 36px;
                text-transform: uppercase;
            }
        }
        .containerValue {
            .containerValueItem {
                display: flex;
                justify-content: center;
                align-items: center;
                hr {
                    height: 7px;
                    width: 160px;
                    background: #259eb3;
                    border: 0;
                }
                h3 {
                    background: #259eb3;
                    color: #fff;
                    font-size: 40px;
                    border-radius: 10px;
                    margin: 0;
                    padding: 10px 50px;
                }
            }
            p {
                color: #259eb3;
                font-size: 24px;
            }
        }
    }
    .containerCardBlock {
        .card-group {
            margin-bottom: 15px;
            .card {
                max-width: 32%;
                padding: 15px;
                margin-bottom: 15px;
                margin: 0 7.5px 15px;
                border: 1px solid #259eb3;
                flex-basis: auto;
                // &:hover {
                //     box-shadow: 0 0 15px #259eb3;
                // }
                .card-title {
                    text-align: center;
                    padding: 0 35px;
                    height: 95px;
                    overflow: hidden;
                    margin-bottom: 0;
                }
                .znak {
                    position: relative;
                    cursor: pointer;
                    &:before {
                        content: "";
                        background-image: url(../img/eurocontainer-znak-big.png);
                        background-size: 180px 20px;
                        background-position: top;
                        width: 100%;
                        height: 20%;
                        background-repeat: no-repeat;
                        margin-top: 48%;
                        position: absolute;
                    }
                }
                .card-color-block {
                    margin: 15px 0;
                    text-align: center;
                    z-index: 9;
                    height: 40px;
                    a {
                        cursor: pointer;
                        img {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                        }
                        & + a {
                            margin-left: 5px;
                        }
                    }
                }
                .card-manufacturer {
                    text-align: center;
                    .country {
                        font-family: RobotoCondensedRegular;
                    }
                }
                .price {
                    text-align: center;
                    p {
                        color: #259eb3;
                        font-size: 30px;
                    }
                }
                .popup {
                    text-align: center;
                    
                    .btn {
                        background: #259eb3;
                        color: #fff;
                        padding: 5px 40px;
                        border-radius: 0;
                        // transition: .5s;
                        &:hover {
                            background: #fff;
                            color: #259eb3;
                            border-bottom: 1px solid #259eb3;
                        }
                    }
                    .modal-dialog {
                        max-width: 100%;
                    }
                    .description {
                        text-align: left;
                        font-family: RobotoCondensedRegular;
                        padding: 20px;
                        line-height: 2;
                        .features-table-title {
                            background: #259eb3;
                            text-align: center;
                            font-size: 25px;
                            color: #fff;
                            text-transform: uppercase;
                        }
                        .table {
                            margin: 0 auto !important;
                            max-width: 55% !important;
                            text-align: center !important;
                        }
                    }
                }
                .download {
                    text-align: center;
                    margin: 20px 0 0;
                    a {
                        color: #000;
                        text-decoration: none;
                        // transition: all .5s ease;
                        &:hover {
                            color: #259eb3;
                        }
                        &:hover img {
                            content: url(../img/download-hover.svg);
                        }
                        img {
                            width: 18px;
                            margin-right: 10px;
                        }
                    }
                }
                .containerBtn {
                    text-align: center;
                    margin-top: 20px;
                    .btn {
                        background: #f7c507 !important;
                        text-transform: uppercase !important;
                        font-size: 21px !important;
                        padding: 10px 42px !important;
                        // transition: none;
                        &:hover {
                            background: linear-gradient(to bottom, #fae490 0%, #f7c507 100%) !important;
                        }               
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 991.98px) {
    .containers {
        background: linear-gradient(#d5eef3,#fff 2%);
        margin-top: -10px;
        .containerHeader {
            padding: 20px 0 0;
            .containerTitle {
                h2 {
                    font-size: 24px;
                }
            }
            .containerValue {
                .containerValueItem {
                    hr {
                        width: 70px;
                        height: 3px;
                        margin: 0;
                    }
                    h3 {
                        font-size: 26px;
                        padding: 5px 26px;
                    }
                }
                p {
                    font-size: 18px;
                }
            }
        }
        .containerCardBlock {
            .card-group {
                .card {
                    max-width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 991.98px) {
    .containers  {
        .containerCardBlock {
            .card {
                max-width: 47% !important;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
    .containers  {
        .containerCardBlock {
            .card {
                max-width: 31% !important;
            }
        }
    }
}

// containers-close