// COMPONENTS
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "components/slick.scss";
@import "components/slick-theme.scss";
@import "components/magnific.scss";
@import "components/fonts.scss";
// PAGE 
@import "page/header.scss";
@import "page/containers.scss";
@import "page/customer.scss";
@import "page/carousel.scss";
@import "page/customerCarousel.scss";
@import "page/services.scss";
@import "page/form.scss";
@import "page/footer.scss";

@import "page/containerModalWindow.scss";


.fade:not(.show) {
    opacity: 1 !important;
}
/* overlay at start */
.mfp-fade.mfp-bg {
    opacity: 0;
  
    -webkit-transition: all 0.35s ease-out;
    -moz-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
  }
  /* overlay animate in */
  .mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
  }
  /* overlay animate out */
  .mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
  }
  
  /* content at start */
  .mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
  
    -webkit-transition: all 0.35s ease-out;
    -moz-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
  }
  /* content animate it */
  .mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
  }
  /* content animate out */
  .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
  }