@font-face {
    font-family: "OpenSansBoldItalic";
    src: url("../fonts/OpenSans/OpenSans-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: bold;
}
@font-face {
    font-family: "RobotoCondensedBold";
    src: url("../fonts/RobotoCondensed/RobotoCondensed-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}
@font-face {
    font-family: "RobotoCondensedBoldItalic";
    src: url("../fonts/RobotoCondensed/RobotoCondensed-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: bold;
}
@font-face {
    font-family: "RobotoCondensedItalic";
    src: url("../fonts/RobotoCondensed/RobotoCondensed-Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}
@font-face {
    font-family: "RobotoCondensedLight";
    src: url("../fonts/RobotoCondensed/RobotoCondensed-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "RobotoCondensedLightItalic";
    src: url("../fonts/RobotoCondensed/RobotoCondensed-LightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 300;
}
@font-face {
    font-family: "RobotoCondensedRegular";
    src: url("../fonts/RobotoCondensed/RobotoCondensed-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}